import React from 'react';
import PrettyMap from './PrettyMap';

class App extends React.Component {

  render(){
    return <div><PrettyMap/></div>
  }
};

export default App;
